import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import Header from '../components/header'
import Breadcrumb from '../components/breadcrumb'
import Footer from '../components/footer'
import Slicker from '../components/slicker'
import Seo from '../components/seo'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'
import pathspliter from '../functions/pathspliter'
import ScrollIntoView from 'react-scroll-into-view'


class About extends Component {

  render() {
    var data= this.props.data.drupal.mainabout.entities[0]
    var team = this.props.data.drupal.team.entities
    return (
      <div>
        <Seo canonical={`${this.props.pageContext.baseurl}${this.props.path}`} metainfo={this.props.data.drupal.metainfo.entities[0].entityMetatags} />
         <Header contactinfo={this.props.pageContext.contactinfo.entities[0]} langcode={this.props.pageContext.langcode} langcontainer={this.props.pageContext.langcontainer}  path={this.props.path} menu={this.props.pageContext.menu}/>
         <Breadcrumb path={pathspliter(this.props.path)} title={data.title}/>
          <main>
         <Container>
            <section className="py-6">
              <Row>
                  <Col md={6}>
                  <div className="wpb_wrapper mb-3">
               <h2 className="section-title">{data.fieldStudioDscrTitle}</h2>
               <div dangerouslySetInnerHTML={{ __html:data.body.processed }}></div>

             <ScrollIntoView className="ys-btn btn custom_btn btn-style-2 btn-medium mt-4" selector="#team">

                {this.props.pageContext.langcode === 'EN'? "Meet our team": "Η ομαδα μας"}

              </ScrollIntoView>

                    </div>
                  </Col>
                  <Col md={6}>
                  <figure className="wpb_wrapper vc_figure">
       <div className="vc_single_image-wrapper   vc_box_border_grey">
       <Img fluid={data.fieldStudioMainImage.entity.thumbnail.imageFile.childImageSharp.fluid} class="vc_single_image-img attachment-full" alt={data.fieldStudioMainImage.entity.thumbnail.alt} />
       </div>
              </figure>
                  </Col>

              </Row>

             </section>

             <section className="py-6 slickabout px-3">
                <h2 className="section-title text-center">{data.fieldStudioImagesTitle}</h2>

                <Slicker images={data.fieldStudioImages}/>

             </section>

             <section  className="py-6">
                     <h2 id="team" className="section-title text-center">{data.fieldStudioTeamTitle}</h2>
                     {team.map((person,i) => {
                       return (
                         <Row key={person.entityId} className={`mb-5 ${i%2===0 ? "reverse": ""}` }>
                             <Col md={8}>
                             <div className="wpb_wrapper mb-3">
                          <h4 className="section-title mb-1">{person.title}</h4>
                          <div className="mb-2"><span>{person.fieldSubName}</span></div>
                          <div className="greenparas p-4" dangerouslySetInnerHTML={{ __html:person.body.processed }}>

                           </div>


                               </div>
                             </Col>
                             <Col md={4}>
                             <figure className="wpb_wrapper vc_figure">
                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                  <Img style={{maxWidth:'100%'}} fixed={person.fieldMemberImage.entity.thumbnail.imageFile.childImageSharp.fixed} alt={person.fieldMemberImage.entity.thumbnail.alt}/>
                  </div>
                         </figure>
                             </Col>

                         </Row>
                       )
                     })}



             </section>

         </Container>
        </main>
        <Footer social={this.props.pageContext.social} langcode={this.props.pageContext.langcode}/>

      </div>
    );
  }

}

export default About;


export const query = graphql`
  query($langcode: DRUPAL_LanguageId) {
    drupal {

      mainabout:nodeQuery(filter: {conditions: [{field: "type", value: "about", language:$langcode}]}) {
        entities(language:$langcode) {


              ... on DRUPAL_NodeAbout {
                title
                fieldStudioImagesTitle
                fieldStudioDscrTitle
                fieldStudioTeamTitle
                entityLanguage{
                  id
                }
                entityUrl{
                  path
                }


                body {
                  processed
                }
                fieldStudioMainImage {
                  entity {
                    thumbnail {
                      url
                      alt
                      imageFile {
                        childImageSharp {
                          fluid(quality: 80, maxWidth: 700) {
                            ...GatsbyImageSharpFluid_withWebp
                            src
                          }
                        }
                      }
                    }
                  }
                }
                fieldStudioImages {
                  entity {
                    thumbnail {
                      url
                      alt
                      imageFile {
                        childImageSharp {
                          fixed(quality: 80, width: 340, height: 320) {
                            ...GatsbyImageSharpFixed_withWebp
                            src
                          }
                        }
                      }
                    }
                  }
                }
              }


        }
      }



      team: nodeQuery(filter: {conditions: [{field: "type", value: "team_member", language:$langcode}]}, sort: [{field: "sticky"}]) {
   entities(language:$langcode) {



    ... on DRUPAL_NodeTeamMember {
      entityId
      entityLanguage{
               id
             }
      title
      fieldSubName
      body {
        processed
      }
      fieldMemberImage {
        entity {
          thumbnail {
            url
            alt
            imageFile {
              childImageSharp {
                        fixed(quality: 80, width: 400, height:600) {
                          src
                          ...GatsbyImageSharpFixed_withWebp
                        }
                      }
            }
          }
        }
      }
    }


   }
   }

   metainfo:nodeQuery(filter: {conditions: [{field: "type", value: "about"}]}) {
  entities(language:$langcode) {
    ... on DRUPAL_NodeAbout {

      entityMetatags {
        value
        key
      }

    }
  }
}





}
  }
`
