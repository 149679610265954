import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img from 'gatsby-image'

var settings = {
     dots: false,
     arrows:true,
     infinite: true,
     speed: 500,
     slidesToShow: 3,
     slidesToScroll: 1,
     centerMode: true,
     centerPadding: 0
   };

class Slicker extends Component {

  render() {
    return (
      <div>
      <Slider arrows={true} responsive={[
{
breakpoint: 1024,
settings: {
slidesToShow: 2,

infinite: true,
dots: false
}
},
{
breakpoint: 600,
settings: {
slidesToShow: 1,


}
},
{
breakpoint: 480,
settings: {
slidesToShow: 1,
slidesToScroll: 1,

}
}
// You can unslick at a given breakpoint now by adding:
// settings: "unslick"
// instead of a settings object
]} {...settings} >

{this.props.images.map((item,i) => {
  return (
    <div key={i} className="text-center">


    <Img   fixed={item.entity.thumbnail.imageFile.childImageSharp.fixed} alt={item.entity.thumbnail.url}/>

    </div>
  )
})}














</Slider>

      </div>
    );
  }

}

export default Slicker;
